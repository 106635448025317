import React from "react";
import { withStyles } from "@material-ui/core";
import misyonImg from "../../../images/misyon.png";
import SubPageBase from "../../../components/SubPageBase";
import Link from "gatsby-link";
import codesignImg from "../../../images/surdur/codesign.jpg";
import TwoColumn from "../../../components/home/TwoColumn";
import atolyeImg from "../../../images/surdur/dongu/atolye.jpg";
import ManifestCenter from "../../../components/home/ManifestCenter";
import ssfImg from "../../../images/surdur/ssf/ssf.jpg";

const styles = () => ({
  root: {
    fontSize: "1.5em",
  },
  content: {
    backgroundColor: "#EFF2F7",
    paddingBottom: "10em",

    "& div:first-child": {
      marginBottom: "2em"
    },

  }
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"SÜRDÜRÜLEBİLİRLİK"}  img={misyonImg} className={classes.root} objectPosition="top">
    <div className={classes.content}>

      <TwoColumn
        imageSrc={codesignImg}
        header="ÇEVRESEL ETKİYİ AZALTMA"
        text={
          <p>Amacımız büyük veya küçük demeden her alanda çevreye etkimizi azaltmak.<br />
            <Link to='cevresel-etkiyi-azaltma'>Keşfet</Link>
          </p>
        }
        textFirst={true}
      />

      <TwoColumn
        imageSrc={atolyeImg}
        header="DÖNGÜSEL EKONOMİ"
        text={
          <p>Spor ürününün ömrünü uzatacak bakım hizmetlerimizden faydalan.<br />
            <Link to='dongusel-ekonomi'>Keşfet</Link>
          </p>
        }
      />

      <TwoColumn
        imageSrc={ssfImg}
        header="SOSYAL SORUMLULUK VE FARKINDALIK"
        text={
          <p>Daha sürdürülebilir bir gelecek için biz tek başımıza yeterli değiliz, bunu biliyoruz..<br />
            <Link to='sosyal-sorumluluk-ve-farkindalik'>Keşfet</Link>
          </p>
        }
        textFirst={true}
      />

      <ManifestCenter
        text={ <>Decathlon'un sürdürülebilir kalkınma dönüşümünü gerçekleştirebilmek için; 30'dan fazla ülkeden, insan kaynakları, kalite, uyum ve finans ekiplerimizden takım arkadaşları ile bir araya geldik ve 2020-2026 Sürdürülebilir Kalkınma Dönüşüm Planı'nı oluşturduk. Planımız üç ana bölüm üzerine inşa edildi: İnsanları geliştirmek, doğayı korumak ve sürdürülebilir değer yaratmak.

          <br/>
          <a href='https://docs.google.com/presentation/d/1Bep50HlpuLAiw8Q60Tumob5oOaQvKAuQsLJ_ypYw6JU/present?slide=id.p4'>Tüm taahhütlerimizin yer aldığı 2020-2026 Dönüşüm Planımızı keşfet.</a>
        </>}
      />

    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
